export const  Bloglist = [
    {
      sno: 1,
      title: "Erasing poverty",
      author: "Carrie Thomas",
      Datepublished: "3rd March 2020",
      desc:"We should erase poverty from the face of earth.",
      category:"Horror"
    },
    {
      sno: 2,
      title: "Erasing hunger",
      author: "John Wilkins",
      Datepublished: "16th August 2022",
      desc:"We should erase hunger from the dead countries.",
      category:"Action"
    },
    {
      sno: 3,
      title: "Erasing hunger",
      author: "John Wilkins",
      Datepublished: "16th August 2022",
      desc:"We should erase hunger from the dead countries.",
      category:"Action"
    },
    {
      sno: 4,
      title: "Erasing hunger",
      author: "John Wilkins",
      Datepublished: "16th August 2022",
      desc:"We should erase hunger from the dead countries.",
      category:"Horror"
    },
    {
      sno: 5,
      title: "Erasing hunger",
      author: "John Wilkins",
      Datepublished: "16th August 2022",
      desc:"We should erase hunger from the dead countries.",
      category:"Horror"
    },
    {
      sno: 6,
      title: "Erasing hunger",
      author: "John Wilkins",
      Datepublished: "16th August 2022",
      desc:"We should erase hunger from the dead countries.",
      category:"Horror"
    },
    {
      sno: 6,
      title: "Erasing hunger",
      author: "John Wilkins",
      Datepublished: "16th August 2022",
      desc:"We should erase hunger from the dead countries.",
      category:"Horror"
    },
    {
      sno: 6,
      title: "Erasing hunger",
      author: "John Wilkins",
      Datepublished: "16th August 2022",
      desc:"We should erase hunger from the dead countries.",
      category:"Action"
    },
    {
      sno: 6,
      title: "Erasing hunger",
      author: "John Wilkins",
      Datepublished: "16th August 2022",
      desc:"We should erase hunger from the dead countries.",
      category:"Action"
    },
    {
      sno: 6,
      title: "Erasing hunger",
      author: "John Wilkins",
      Datepublished: "16th August 2022",
      desc:"We should erase hunger from the dead countries.",
      category:"Thriller"
    },
    {
      sno: 6,
      title: "Erasing hunger",
      author: "John Wilkins",
      Datepublished: "16th August 2022",
      desc:"We should erase hunger from the dead countries.",
      category:"Thriller"
    },
    {
      sno: 6,
      title: "Erasing hunger",
      author: "John Wilkins",
      Datepublished: "16th August 2022",
      desc:"We should erase hunger from the dead countries.",
      category:"Action"
    },
    {
      sno: 16,
      title: "Erasing dance",
      author: "John Wilkins",
      Datepublished: "16th August 2022",
      desc:"We should erase hunger from the dead countries.",
      category:"Thriller"
    },
    {
      sno: 7,
      title: "Erasing hunger",
      author: "John Wilkins",
      Datepublished: "16th August 2022",
      desc:"We should erase hunger from the dead countries.",
      category:"Thriller"
    }
  ] 