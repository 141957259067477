import React from 'react'
import './css/Tronso.css'
export default function Tronso() {
  return (
    <div className='Backgy'>
        <div className="trontitle">
        <h1>A simple Platform.sh</h1>
        <p>This example runs Springboot as a backend / API and a React frontend </p>
        </div>
        
    </div>
  )
}
