import React from 'react'
import './css/Footer.css'
export default function Footer() {
  return (
    <div className='footbod'>
        <footer class="site-footer">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            {/* <h6>About</h6> */}
            <p class="text-justify">Made by Kaustubh Pandey</p>
          </div>

        </div>
        <hr/>
      </div>
      <div class="container">
        <div class="row">

          
        </div>
      </div>
</footer>
    </div>
  )
}
